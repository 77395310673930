import React from "react";
import { Parallax } from "react-scroll-parallax";
import { Titelscroll } from "../components/Titelscroll";

//logo
import typescript from "../resources/icons/ts_logo.png";
import postgresql from "../resources/icons/postgresql_logo.png";
import symfony from "../resources/icons/symfony_logo.png";
import react from "../resources/icons/react_logo.png";
import tailwind from "../resources/icons/tailwind_logo.png";
import firebase from "../resources/icons/firebsae_logo.png";
import java from "../resources/icons/java_logo.png";

export const Skills = () => {
  return (
    <div id="skills" className=" bg-black dark:bg-[#FF81B2]">
      <div className="sticky top-0">
        <Titelscroll titel="SKILLS" />
      </div>

      <div className="hidden md:block py-16">
        {/* 
        <div className="flex gap-4 flex-row-reverse mr-12 mb-4">
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2 "
            speed={10}
          >
            <img src={symfony} alt="C# Logo" className="w-10 h-10"></img>
            <h1 className="text-lg font-bold">Symfony</h1>
          </Parallax>
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2"
            speed={40}
          >
            <img src={tailwind} alt="Tailwind Logo" className="w-10 h-10"></img>
            <h1 className="text-lg font-bold">Tailwind CSS</h1>
          </Parallax>
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2"
            speed={20}
          >
            <img src={firebase} alt="Firebase Logo" className="w-10 h-10"></img>
            <h1 className="text-lg font-bold">Firebase</h1>
          </Parallax>
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2"
            speed={60}
          >
            <img src={react} alt="React.js Logo" className="w-10 h-10"></img>
            <h1 className="text-lg font-bold">React.js</h1>
          </Parallax>
        </div>
        */}

        <div className="flex gap-4 flex-row-reverse mr-12 mb-4">
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2 "
            speed={10}
          >
            <img src={symfony} alt="C# Logo" className="w-10 h-10"></img>
            <h1 className="text-lg font-bold">Symfony</h1>
          </Parallax>
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2"
            speed={50}
          >
            <img src={tailwind} alt="Tailwind Logo" className="w-10 h-10"></img>
            <h1 className="text-lg font-bold">Tailwind CSS</h1>
          </Parallax>
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2"
            speed={20}
          >
            <img src={firebase} alt="Firebase Logo" className="w-10 h-10"></img>
            <h1 className="text-lg font-bold">Firebase</h1>
          </Parallax>
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2"
            speed={50}
          >
            <img src={react} alt="React.js Logo" className="w-10 h-10"></img>
            <h1 className="text-lg font-bold">React.js</h1>
          </Parallax>
        </div>

        <div className="flex gap-4 flex-row-reverse mr-12">
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2"
            speed={10}
          >
            <img src={java} alt="Java Logo" className="w-10 h-10"></img>
            <h1 className="text-lg font-bold">Java</h1>
          </Parallax>
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2 "
            speed={50}
          >
            <img
              src={postgresql}
              alt="Database Logo"
              className="w-10 h-10"
            ></img>
            <h1 className="text-lg font-bold">PostgreSQL</h1>
          </Parallax>
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2 "
            speed={20}
          >
            <img
              src={react}
              alt="React native Logo"
              className="w-10 h-10"
            ></img>
            <h1 className="text-lg font-bold">React native</h1>
          </Parallax>
          <Parallax
            className="bg-white h-24 w-56 rounded-lg flex justify-center items-center gap-2 mb-32"
            speed={50}
          >
            <img
              src={typescript}
              alt="JavaScript Logo"
              className="w-10 h-10"
            ></img>
            <h1 className="text-lg font-bold">TypeScript</h1>
          </Parallax>
        </div>
      </div>
      <div className="md:hidden flex flex-col items-center gap-7 mt-4">
        <div className="flex gap-1 p-2 bg-white w-1/2 rounded-md justify-center ml-20">
          <img src={react} alt="React.js Logo" className="w-8 h-8"></img>
          <h1 className="text-lg font-bold">React.js</h1>
        </div>
        <div className="flex gap-1 p-2 bg-white w-1/2 rounded-md justify-center mr-20">
          <img src={firebase} alt="Firebase Logo" className="w-8 h-8"></img>
          <h1 className="text-lg font-bold">Firebase</h1>
        </div>
        <div className="flex gap-1 p-2 bg-white w-1/2 rounded-md justify-center ml-20">
          <img src={tailwind} alt="Tailwind Logo" className="w-8 h-8"></img>
          <h1 className="text-lg font-bold">Tailwind</h1>
        </div>
        <div className="flex gap-1 p-2 bg-white w-1/2 rounded-md justify-center mr-20">
          <img src={java} alt="Java Logo" className="w-8 h-8"></img>
          <h1 className="text-lg font-bold">Java</h1>
        </div>
        <div className="flex gap-1 p-2 bg-white w-1/2 rounded-md justify-center ml-20">
          <img src={react} alt="React native Logo" className="w-8 h-8"></img>
          <h1 className="text-lg font-bold">React native</h1>
        </div>
        <div className="flex gap-1 p-2 bg-white w-1/2 rounded-md justify-center mr-20">
          <img src={typescript} alt="JavaScript Logo" className="w-8 h-8"></img>
          <h1 className="text-lg font-bold">TypeScript</h1>
        </div>
        <div className="flex gap-1 p-2 bg-white w-1/2 rounded-md justify-center ml-20">
          <img src={postgresql} alt="Database Logo" className="w-8 h-8"></img>
          <h1 className="text-lg font-bold">PostgreSQL</h1>
        </div>
        <div className="flex gap-1 p-2 bg-white w-1/2 rounded-md justify-center mr-20">
          <img src={symfony} alt="C# Logo" className="w-8 h-8"></img>
          <h1 className="text-lg font-bold">Symfony</h1>
        </div>
      </div>
    </div>
  );
};
