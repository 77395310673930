import React from "react";
import { Parallax } from "react-scroll-parallax";
import { ToDoList } from "../components/projects/ToDoList";
import { Titelscroll } from "../components/Titelscroll";
import { Scholarlink } from "../components/projects/Scholarlink";
import { YapYard } from "../components/projects/YapYard";

export const Projects = () => {
  return (
    <div id="projects" className="dark:bg-[#B7F385] overflow-hidden">
      <div className="sticky top-0">
        <Titelscroll titel="PROJECTS" />
      </div>
      <Parallax className="mt-44 md:mt-0">
        <Scholarlink />
        <YapYard />
        <ToDoList />
        {/* More Projects:
      <MoreProjects></MoreProjects> */}
        {/*        <h2 className="text-center text-4xl font-bold mt-20">
          Meine Open-Source Projekte findest du auf <br />{" "}
          <a className="font-extrabold text-transparent text-6xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 ">
            GitHub
          </a>
        </h2>*/}
      </Parallax>
    </div>
  );
};
