import React from "react";
import { Parallax } from "react-scroll-parallax";

import scholarlink_desktop from "../../resources/mockups/scholarlink_mockup1.png";
import scholarlink_mobile from "../../resources/mockups/scholarlink_mockup2.png";

export const Scholarlink = () => {
  return (
    <div className="h-screen grid grid-cols-2 items-center md:-mt-40 -mt-64">
      <div className="flex flex-col">
        <div className="ml-5 md:ml-20">
          <a href="https://scholarlink.ch/" target="_blank">
            <h1 className="text-4xl md:text-8xl mb-12  font-mono transition-transform hover:translate-x-2">
              Scholarlink
            </h1>
          </a>
          <div className="flex mb-6"></div>
          <p className="md:text-4xl">
            Ein Portal auf welchem Schülerinnen und Schüler einander Nachhilfe
            anbieten können.
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <Parallax speed={10} className="absolute">
          <img
            src={scholarlink_desktop}
            alt="Mockup"
            className=" w-5/6  hidden md:block "
          />
        </Parallax>
        <Parallax speed={40} className="">
          <img src={scholarlink_mobile} alt="Mockup" className="w-full md:w-72 md:ml-80" />
        </Parallax>
      </div>
    </div>
  );
};
