import React from "react";
import { Parallax } from "react-scroll-parallax";
import yapyard_mobile1 from "../../resources/mockups/yapyard_mockup1.png";
import yapyard_mobile2 from "../../resources/mockups/yapyard_mockup2.png";

export const YapYard = () => {
  return (
    <div className="h-screen grid grid-cols-2 items-center md:-mt-40 -mt-64">
      <div className="flex flex-col">
        <div className="ml-5 md:ml-20">
          <a href="https://yapyard.elia-ritzmann.ch/" target="_blank">
            <h1 className="text-4xl md:text-8xl mb-12 font-mono transition-transform hover:translate-x-2">
              YapYard
            </h1>
          </a>
          <div className="flex mb-6"></div>
          <p className="md:text-4xl">
            Eine React Native App mit Firebase im Backend ermöglicht es
            Benutzern, mit anderen Nutzern in ihrer Umgebung zu chatten, sofern
            sie sich innerhalb eines bestimmten Radius befinden. Die App bietet
            zudem Benachrichtigungen über Personen in der Nähe sowie eine
            Login-Funktion mit Apple.
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <Parallax speed={40} className="pt-48">
          <img src={yapyard_mobile1} alt="Mockup" className="w-80" />
        </Parallax>
        <Parallax speed={80}>
          <img
            src={yapyard_mobile2}
            alt="Mockup"
            className="w-80 hidden md:block"
          />
        </Parallax>
      </div>
    </div>
  );
};
